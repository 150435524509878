<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <v-row class="flex-column" no-gutters>
      <div class="title">
        <h1>
          {{
            $t('labels.details.title', {
              operation: $t(`labels.details.${isEdit() ? 'update' : 'add'}`),
            })
          }}
        </h1>
        <v-divider />
      </div>
    </v-row>

    <v-form ref="form" @submit.prevent="saveLabel">
      <v-row>
        <v-col cols="12" lg="3" class="ml-3">
          <v-combobox
            id="iiotAddEditLabelAutocompleteKey"
            ref="key"
            v-model="label.key"
            class="mt-3"
            :items="keys"
            :label="$t('labels.details.labelKey')"
            :search-input.sync="searchKey"
            :rules="[rules.required, rules.labelKeyPattern, rules.maxLength]"
            :disabled="isEdit() && !canAccess('UI_LABEL:EDIT')"
            autofocus
            @keydown="getKeys"
            @focus="fetchLabels({ value: '' })"
            @update:search-input="setKey"
          />
        </v-col>
        <v-col cols="12" lg="3" class="ml-3">
          <v-combobox
            id="iiotAddEditLabelAutocompleteValue"
            ref="value"
            v-model="label.value"
            class="mt-3"
            :search-input.sync="searchValue"
            :items="values"
            :label="$t('labels.details.labelValue')"
            :rules="[rules.required, rules.labelValuePattern, rules.maxLength]"
            :disabled="isEdit() && !canAccess('UI_LABEL:EDIT')"
            @keydown="getValues"
            @focus="fetchLabels({ key: '' })"
            @update:search-input="setValue"
          />
        </v-col>
      </v-row>
      <v-col>
        <v-row>
          <v-col cols="12">
            <div>
              <nerve-button
                id="iiotAddEditLabelCancel"
                :text="$t('labels.details.cancel')"
                type-of-btn="cancel"
                size="normal"
                class="mr-5 ml-0"
                @click-event="back()"
              />
              <nerve-button
                v-if="
                  (!isEdit() && !canAccess('UI_LABEL:EDIT')) ||
                  (isEdit() && canAccess('UI_LABEL:EDIT')) ||
                  (!isEdit() && canAccess('UI_LABEL:CREATE'))
                "
                id="iiotAddEditLabelSave"
                :text="$t(`labels.details.${isEdit() ? 'update' : 'save'}`)"
                type-of-btn="action"
                size="normal"
                type="submit"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { NerveButton } from 'nerve-ui-components';
import { KEY_VALUE_LABEL_LENGTH, VALIDATION_REGEX } from '@/constants';
import Logger from '@/utils/logger';

export default {
  components: { NerveButton },
  data: () => ({
    searchKey: '',
    searchValue: '',
    isRequestInProgress: false,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('labels.details.required'),
        labelKeyPattern: (value) =>
          VALIDATION_REGEX.LETTER_NUMBER_DASH_AND_UNDERSCORE.test(value) || this.$t('labels.details.labelKeyPattern'),
        labelValuePattern: (value) =>
          VALIDATION_REGEX.PREVENT_WHITESPACE.test(value) || this.$t('labels.details.labelValuePattern'),
        maxLength: (value) =>
          (value && value.length <= KEY_VALUE_LABEL_LENGTH) ||
          this.$t('labels.details.maxLength', { fieldLength: KEY_VALUE_LABEL_LENGTH }),
      };
    },

    label() {
      return this.$store.getters['labels/label'];
    },

    keys() {
      return this.$store.getters['labels/keys'];
    },

    values() {
      return this.$store.getters['labels/values'];
    },
  },
  mounted() {
    this.$nextTick(() => {
      let id = '';
      if (this.isEdit()) {
        // eslint-disable-next-line prefer-destructuring
        id = window.location.pathname.split('/').reverse()[0];
      }
      this.$refs.form.resetValidation();
      this.getLabelById(id);
    });
  },
  methods: {
    async saveLabel() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isRequestInProgress = true;

      try {
        if (this.label && this.label._id) {
          await this.$store.dispatch('labels/update', this.label);
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'labels.details.successUpdate',
            color: 'success',
            showClose: true,
          });
        } else {
          await this.$store.dispatch('labels/create', this.label);
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'labels.details.successCreate',
            color: 'success',
            showClose: true,
          });
        }
      } catch (e) {
        return;
      }
      this.isRequestInProgress = false;
      this.back();
    },

    back() {
      this.$router.go(-1);
    },

    // eslint-disable-next-line func-names
    getKeys: debounce(async function () {
      if (!this.$refs.key.validate()) {
        return;
      }
      await this.fetchLabels({ key: this.searchKey || '' });
    }, 500),

    // eslint-disable-next-line func-names
    getValues: debounce(async function () {
      if (!this.$refs.value.validate()) {
        return;
      }
      await this.fetchLabels({ value: this.searchValue || '' });
    }, 500),

    getLabelById(id) {
      try {
        this.$store.dispatch('labels/getById', id);
      } catch (e) {
        Logger.error(e);
      }
    },

    isEdit() {
      return (
        !(window.location.pathname.split('/').reverse()[0] === 'new') &&
        !(window.location.pathname.split('/').reverse()[0] === 'labels')
      );
    },

    async fetchLabels(filterBy) {
      try {
        await this.$store.dispatch('labels/fetchForAutocomplete', {
          limit: 10,
          page: 1,
          filterBy,
        });
      } catch (e) {
        Logger.error(e);
      }
    },

    setKey(e) {
      this.label.key = e ? e.toString() : '';
    },
    setValue(e) {
      this.label.value = e ? e.toString() : '';
    },
  },
};
</script>
